import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"

const Heilig = () => {
  const data = useStaticQuery(graphql`
    query Heilig {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Der Hölderlinturm ist für viele weit mehr als ein Museum - manchen gilt er gar als heiliger Ort. 
        Davon zeugt nicht nur die überbordende künstlerische Rezeption des Ortes über alle Kunstgattungen hinweg. 
        Doch wie entsteht eine solche Zuschreibung und wie passt sie damit zusammen, dass von dem Ort, in dem Hölderlin gewohnt hat, 
        heute kaum noch etwas übrig ist? Stefan Knödler zeigt, wie Hölderlin selbst das Konzept des Heiligen in seiner Dichtung verankerte, 
        wie sein Leben - insbesondere seine Jahre im Turm - zur Legendenbildung beitrug 
        und wie seine Verehrung durch Künstler_innen im Laufe des 20. Jahrhunderts ein eigenes Narrativ schuf. 
        Zudem wird der Blick auf die heutige „Gedenkstätte“ geworfen: Inwiefern beeinflusst sie unsere Wahrnehmung Hölderlins 
        und seines Werkes?
      </Paragraph>
      <Paragraph>
        PD Dr. Stefan Knödler ist wissenschaftlicher Mitarbeiter am Deutschen Seminar der Universität Tübingen.
      </Paragraph>
            <Stack space={6}>
                    <Heading as="h2" level={5}>
                      Anmeldung
                    </Heading>
                    <Paragraph>
                        Anmeldung telefonisch unter 07071/2041862, oder:{" "}
                        <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen#step=1">
                          <span style={{color: '#F7A61B'}}> Hier</span> 
                        </ExternalLink>
                    </Paragraph>
            </Stack>
    </Stack>
  )
}

export default Heilig